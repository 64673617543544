<template>
  <div class="modalbox modalStep" id="waypointModal">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <img class="btn-previous" :src="require('@/assets/img/arrows/previous.svg')" @click="goPreviousPage" />
      <router-link v-if="
        currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
      " class="ml-auto mr-2" :to="'/formations/' + $route.params.idTraining + '/suivi/validationSteps'
        ">
        <b-button type="is-outlined" rounded size="is-small">Formation</b-button>
      </router-link>
      <router-link class="mr-2" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/parcours'
        ">
        <b-button type="is-outlined" rounded size="is-small">Parcours</b-button>
      </router-link>
      <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/waypoint'
        ">
        <b-button type="is-outlined" rounded size="is-small">Livret de suivi</b-button></router-link>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading" style="background: rgba(255, 255, 255, 0.8)">
    </b-loading>
    <section class="w-100 container" v-if="booklet_validation.data">
      <div class="title-paragraph" v-if="booklet_validation.data">
        {{ booklet_validation.data[0].data[0].part_name }}
      </div>
      <div class="studentName" v-if="currentUser.role !== 'apprenant' && booklet_validation.data">
        {{ this.booklet_validation.data[0].name }}
      </div>
      <div class="has-text-centered mb-2">
        <b-tag type="is-success" v-if="this.validated === 'Effectué'">{{
          this.validated
        }}</b-tag>
        <b-tag type="is-primary" v-else-if="this.validated === 'En cours'">{{
          this.validated
        }}</b-tag>
        <b-tag type="is-danger is-light" v-else-if="this.validated === 'Litige'">{{ this.validated }}</b-tag>
        <b-tag type="is-primary is-outlined" v-else-if="this.validated === 'A faire'">{{ this.validated }}</b-tag>
        <b-tag type="is-info" v-else>{{ this.validated }}</b-tag>
      </div>
      <div class="justify-content-center is-flex align-items-center mb-5">
        du {{ waypoint.start_date | day }} au
        <span class="mr-2 ml-1"> {{ waypoint.end_date | day }}</span>
        <!-- <b-button>modifier</b-button> -->
      </div>
      <div class="is-mobile is-multiline mt-5 mb-5 pb-5" v-if="waypoint && listexperiences[0]">
        <b-steps v-model="activeStep" :animated="true" :rounded="true" :has-navigation="false" :mobile-mode="'compact'">
          <b-step-item step="1" label="Récap" :clickable="true">
            <b-message type="is-danger" has-icon v-if="isConflictual"><b>Attention il y a un litige</b>
              <div>
                <b> Le référent pédagogique doit intervenir.</b>
                Le semestre ne pourra pas être validé tant que la personne
                référente pédagogique n'aura pas débloqué la situation.
              </div>
            </b-message>
            <div>
              <div v-if="this.validation">
                <div class="mb-3" v-if="this.validation.helper">
                  <b-message type="is-warning" class="mt-2 has-text-centered">
                    {{ this.validation.helper }}
                    <div class="has-text-centered">
                      {{ this.validation.content }}
                    </div>
                  </b-message>
                </div>
                <div v-else>
                  Les crédits ECTS sont-ils accordés à cet apprenant•e pour ce
                  semestre ?
                  <div class="has-text-centered">
                    {{ this.validation.content }}
                  </div>
                </div>
              </div>
              <div class="has-text-centered mt-2">
                <b-button @click="modifyTexts" v-if="currentUser.role == 'coordinateur' && !editHelpText"
                  size="is-small"><span v-if="booklet_validation.part_content">modifier</span><span v-else>ajouter une
                    information pour apprenant• et
                    tuteur·trice</span></b-button>
              </div>
              <div class="ck-editor-block mb-0 pb-0 mt-5" v-if="currentUser.role == 'coordinateur' && editHelpText">
                <ckeditor v-model="booklet_validation.part_content" :editor="editor" :config="editorConfig" class="mb-5"
                  :ref="'revisionContent'"></ckeditor>
              </div>
              <div class="has-text-centered">
                <b-button @click="saveHelpTexts" v-if="currentUser.role == 'coordinateur' && editHelpText"
                  size="is-small">enregistrer</b-button>
              </div>
            </div>
            <div class="columns is-mobile is-multiline mt-5" v-if="listexperiences[0].sequences">
              <div class="column is-6-mobile">
                <div class="card card_item card_stat_header card_stat_v2">
                  <div class="stat-cell pointer">
                    <div class="stat_number">{{ doneStudent }}</div>
                    <div class="stat_label">experiences<br />enregistrées</div>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile">
                <div class="card card_item card_stat_header card_stat_v2">
                  <div class="stat-cell pointer">
                    <div class="stat_number has-text-primary">
                      {{ evaluated }}
                    </div>
                    <div class="stat_label">
                      expériences<br />évaluées par le tuteur
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile">
                <div class="card card_item card_stat_header card_stat_v2">
                  <div class="stat-cell pointer">
                    <div class="stat_number has-text-primary">
                      {{ validatedTutor }}
                    </div>
                    <div class="stat_label">
                      expériences<br />validées par le tuteur
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile">
                <div class="card card_item card_stat_header card_stat_v2">
                  <div class="stat-cell pointer">
                    <div class="stat_number">
                      {{ evalTutor }}
                    </div>
                    <div class="stat_label">
                      compétences<br />évaluées par le tuteur
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- {{ listexperiences[0].sequences }} -->
            <div v-if="listexperiences[0].sequences">
              <div class="card card_item is-flex is-vcentered" v-for="(sequence, i) in listexperiences[0].sequences"
                :key="i">
                <span class="has-text-success" v-if="
                  sequence.notes_tuteur > 0 &&
                  sequence.notes_apprenant > 0 &&
                  (sequence.student_sequence_status === 4 ||
                    sequence.student_sequence_status === 7)
                " style="font-size: 0.5em; margin-right: 0.5em; line-height: 3">⬤</span>
                <div v-else class="is-inline-block">
                  <div v-if="i < 3">
                    <span class="has-text-danger" style="
                        font-size: 0.5em;
                        margin-right: 0.5em;
                        line-height: 3;
                      ">⬤</span>
                  </div>
                  <div v-else>
                    <span class="has-text-warning" style="
                        font-size: 0.5em;
                        margin-right: 0.5em;
                        line-height: 3;
                      ">⬤</span>
                  </div>
                </div>
                {{ sequence.sequence_name }}
                <div class="ml-auto">
                  <div class="has-text-centered has-text-white">
                    <b-tag type="is-primary is-light" outlined v-if="i + 1 > 3" class="mr-1">facultatif</b-tag>
                    <b-tag type="is-info" v-if="
                      sequence.notes_tuteur > 0 &&
                      sequence.notes_apprenant > 0 &&
                      sequence.student_sequence_status === 3
                    ">experience à valider</b-tag>
                    <b-tag type="is-success" v-else-if="
                      sequence.notes_tuteur > 0 &&
                      sequence.notes_apprenant > 0 &&
                      sequence.student_sequence_status === 4
                    ">validée</b-tag>
                    <b-tag type="is-danger" v-else-if="sequence.student_sequence_status === 7">litige</b-tag>
                    <span v-else>
                      <b-tag type="is-danger" v-if="i < 3"> incomplet</b-tag><b-tag type="is-danger is-light" outlined
                        v-else>
                        incomplet</b-tag></span>
                  </div>
                </div>
              </div>
              <b-message type="is-danger" v-if="threeSituations < 3"><b>Attention l'apprenant•e doit saisir au moins 3
                  situations et
                  le tuteur ou la tutrice doit avoir évalué les compétences pour
                  ces 3 situations.</b>
                Ensuite, tuteur•trice et apprenant•e doivent avoir comparé leurs
                évaluations et le tuteur ou la tutrice validé son évaluation
                suite à cet échange avec l'apprenant•e.
              </b-message>
              <div v-else>
                <div v-if="this.validation">
                  <b-message type="is-success" v-if="this.validated !== 'Effectué' && !this.validation.validation_id">La
                    fiche semestre peut être validée !</b-message>
                  <b-message type="is-success" v-if="this.validated === 'Effectué'">La fiche semestre est validée
                    !</b-message>
                </div>
                <div v-else>
                  <b-message type="is-success" v-if="this.validated !== 'Effectué'">La
                    fiche
                    semestre peut être validée
                    !</b-message>
                  <b-message type="is-success" v-else>La fiche semestre est validée !</b-message>
                </div>
              </div>
            </div>
          </b-step-item>
          <b-step-item step="2" label="Détail" :clickable="true">
            <b-message type="is-danger" has-icon v-if="isConflictual"><b>Attention il y a un litige</b>
              <div>
                <b> Le référent pédagogique doit intervenir.</b>
                Le semestre ne pourra pas être validé tant que la personne
                référente pédagogique n'aura pas débloqué la situation.
              </div>
            </b-message>
            <b-carousel class="mb-5 pb-5" :arrow="true" :repeat="false" :arrow-hover="false" :autoplay="false"
              @click="keycomponent++" v-if="listexperiences[0].sequences" :key="keycomponent">
              <b-carousel-item class="card card_item" v-for="(sequence, i) in listexperiences[0].sequences" :key="i">
                <section class="hero is-medium">
                  <div class="hero-body has-text-centered">
                    <div class="w-100 has-text-centered">
                      {{ i + 1 }} / {{ listexperiences[0].sequences.length }}
                    </div>
                    <div class="has-text-centered mb-3 has-text-white">
                      <b-tag type="is-primary" outlined v-if="i + 1 > 3" class="mr-1">facultatif</b-tag>
                      <b-tag type="is-success" v-else-if="
                        sequence.notes_tuteur > 0 &&
                        sequence.notes_apprenant > 0 &&
                        sequence.student_sequence_status === 4
                      ">experience ok</b-tag>
                      <b-tag type="is-danger is-light" v-else-if="sequence.student_sequence_status === 7">litige</b-tag>
                      <b-tag type="is-info" v-else-if="
                        sequence.notes_tuteur > 0 &&
                        sequence.notes_apprenant > 0 &&
                        sequence.student_sequence_status === 3
                      ">experience à valider</b-tag>
                      <b-tag type="is-danger" v-else>incomplet</b-tag>
                    </div>
                    <h2 class="title">{{ sequence.sequence_name }}</h2>
                    <div v-html="sequence.student_sequence_subtitle"></div>
                    <router-link :to="'/sequence-edition/' +
                      $route.params.idTraining +
                      '/' +
                      sequence.student_sequence_id +
                      '/' +
                      $route.params.idStudentTraining
                      "><b-button type="is-primary is-outlined" size="is-small" class="mt-3">Voir
                        l'expérience</b-button></router-link>
                  </div>
                  <div v-if="sequence.activities">
                    <b-collapse :open="false" :aria-id="'contentSequence' + i" animation="slide">
                      <template #trigger="props" style="width: 100%; text-align: center">
                        <div class="card card-item card_simple is-flex align-items-center mb-2">
                          <a class="card-header-icon">
                            <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                            </b-icon>
                          </a>
                          <p class="card-header-title">
                            Lire la description de l'activité rédigée par
                            l'apprenant•e
                          </p>
                          <div>
                            <b-tag type="is-success" v-if="
                              sequence.activities[0]
                                .student_activity_status === 3
                            ">effectué</b-tag>
                            <b-tag type="is-danger is-light" v-if="
                              sequence.activities[0]
                                .student_activity_status === 7
                            ">litige</b-tag>
                            <b-tag type="is-primary" v-if="
                              sequence.activities[0]
                                .student_activity_status === 6
                            ">en cours</b-tag>
                            <b-tag type="is-danger is-light" v-if="
                              sequence.activities[0]
                                .student_activity_status === 0 ||
                              sequence.activities[0]
                                .student_activity_status === 1
                            ">non réalisé</b-tag>
                          </div>
                        </div>
                      </template>
                      <div>
                        <div class="subtitle-paragraph has-text-primary has-text-left mt-5">
                          <b>
                            {{ sequence.activities[0].activity_name
                            }}{{ sequence.activities[0].id }}</b>
                        </div>
                        <div v-for="(step, index) in sequence.activities[0].steps" :key="'step' + index">
                          <b class="mb-3 mt-2"> {{ step.step_name }}</b>
                          <div v-if="step.student_step_entry">
                            <div v-html="step.student_step_entry" class="mb-4"></div>
                          </div>
                          <div v-else class="mt-2">
                            <b-message>Champ non rempli</b-message>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                    <b-collapse animation="slide">
                      <template #trigger="props" style="width: 100%; text-align: center">
                        <div class="card card-item card_simple is-flex align-items-center">
                          <a class="card-header-icon">
                            <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                            </b-icon>
                          </a>
                          <div class="card-header-title">
                            Compétences évaluées
                            <div class="is-flex is-vcentered ml-auto mr-2">
                              <b-icon icon="user" type="is-primary"> </b-icon>
                              <b-tag type="is-danger" v-if="sequence.notes_apprenant === 0">{{ sequence.notes_apprenant
                                }}</b-tag>
                              <div v-else class="is-flex is-vcentered">
                                <b-tag :type="sequence.notes_apprenant > 0
                                  ? 'is-success'
                                  : 'is-warning'
                                  ">{{ sequence.notes_apprenant }}</b-tag>
                              </div>
                            </div>
                            <div class="is-flex is-vcentered">
                              <img src="@/assets/img/elements/tutor-eval.svg" width="17" class="mr-1"
                                style="width: 17px" />

                              <b-tag type="is-danger" v-if="sequence.notes_tuteur === 0">{{ sequence.notes_tuteur
                                }}</b-tag>
                              <div v-else class="is-flex is-vcentered">
                                <b-tag :type="sequence.notes_tuteur > 0
                                  ? 'is-success'
                                  : 'is-warning'
                                  ">{{ sequence.notes_tuteur }}</b-tag>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="sequence.notes_apprenant < 1" class="has-text-centered">
                          <b-message type="is-warning">L'apprenant•e doit faire son auto
                            évaluation</b-message>
                          <router-link :to="'/sequence-edition/' +
                            $route.params.idTraining +
                            '/' +
                            sequence.student_sequence_id +
                            '/' +
                            $route.params.idStudentTraining
                            "><b-button rounded type="is-primary" v-if="currentUser.role === 'apprenant'">Faire mon
                              auto-évaluation</b-button></router-link>
                        </div>
                        <div v-if="
                          sequence.notes_apprenant > 0 &&
                          sequence.notes_tuteur < 1
                        " class="has-text-centered">
                          <b-message type="is-warning">Le tuteur ou la tutrice doit faire son
                            évaluation</b-message>
                          <router-link :to="'/sequence-edition/' +
                            $route.params.idTraining +
                            '/' +
                            sequence.student_sequence_id +
                            '/' +
                            $route.params.idStudentTraining
                            "><b-button rounded type="is-primary" v-if="currentUser.role === 'tuteur'">Faire
                              l'évaluation</b-button></router-link>
                        </div>
                        <div v-if="
                          sequence.student_sequence_status === 6 &&
                          sequence.notes_apprenant > 0 &&
                          sequence.notes_tuteur > 0
                        " class="has-text-centered">
                          <b-message type="is-warning">Le tuteur ou la tutrice doit finaliser son
                            évaluation.</b-message>
                        </div>
                        <div v-if="
                          sequence.student_sequence_status === 3 &&
                          sequence.notes_apprenant > 0 &&
                          sequence.notes_tuteur > 0
                        " class="has-text-centered">
                          <b-message type="is-warning">Tuteur·trice et apprenant·e doivent comparer leurs
                            évaluations.</b-message>
                        </div>
                        <div v-if="sequence.student_sequence_status === 4">
                          <b-message type="is-success">Parfait, c'est bon c'est validé !</b-message>
                        </div>
                        <div v-if="sequence.student_sequence_status === 7">
                          <b-message type="is-danger" v-if="
                            currentUser.role === 'apprenant' ||
                            currentUser.role === 'tuteur'
                          ">Tuteur•trice, apprenant•e, les évaluations ont été
                            comparées et un litige a été signalé.</b-message>
                          <b-message v-else type="is-danger">Les évaluations ont bien été comparées et un litige
                            a été signalé.</b-message>
                        </div>
                      </template>
                      <div>
                        <div v-if="sequence.student_sequence_status === 4">
                          <SkillsLevelsComparisonRecap :role="['w_tuteur']" type="sequence"
                            :studentSequence="sequence.student_sequence_id" :studentActivity="sequence.activities[1].student_activity_id
                              " :key="keycomponent" :nomodify="true" />
                        </div>
                        <div v-else-if="
                          sequence.student_sequence_status !== 4 &&
                          sequence.student_sequence_status !== 7
                        ">
                          <div v-if="sequence.student_sequence_status === 3">
                            <div class="bloc-title" v-if="currentUser.role === 'tuteur'">
                              <div class="title-paragraph">
                                Comparez votre évaluation avec celle de votre
                                apprenant•e
                              </div>
                              <div>
                                <div class="subtitle-paragraph mb-4 test">
                                  <div>
                                    Comparez maintenant vos évaluations avec
                                    celles de votre apprenant•e. L'enjeu est de
                                    bien discuter avec votre apprenant•e de ce
                                    qui a été vu, travaillé, acquis et l'aider à
                                    identifier et comprendre où il en est. Vous
                                    pouvez encore modifier et ajuster en
                                    fonction de la discussion avec votre
                                    apprenant•e. Toutefois, si vraiment vous
                                    n'êtes pas d'accord sur le constat, vous
                                    pouvez indiquer un litige. Cela permettra à
                                    l'équipe pédagogique d'identifier qu'il y a
                                    un souci.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bloc-title" v-else-if="currentUser.role === 'apprenant'">
                              <div class="title-paragraph">
                                Évaluation comparée
                              </div>

                              <div>
                                <div class="subtitle-paragraph mb-4 test">
                                  <div>
                                    Comparez maintenant les évaluations
                                    réalisées avec celles de votre tuteur•trice.
                                    L'enjeu est de bien discuter ensemble de ce
                                    qui a été vu, travaillé, acquis et vous
                                    aider à identifier et comprendre où vous en
                                    êtes. C'est à votre tuteur•trice de valider
                                    avec son compte
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <b-message type="is-primary" v-else
                              >Comparez maintenant les évaluations réalisées par
                              chacun.</b-message
                            > -->
                            <div v-if="currentUser.role === 'tuteur'" class="has-text-centered">
                              <b-button type="is-success" rounded class="mr-2" @click="
                                validateDefEval(
                                  sequence.activities[1].student_activity_id,
                                  sequence.student_sequence_id
                                )
                                ">Valider</b-button>
                              <b-button type="is-danger" rounded @click="
                                conflictAlert(
                                  sequence.activities[1].student_activity_id,
                                  sequence.student_sequence_id
                                )
                                ">Signaler un litige</b-button>
                            </div>
                            <SkillsLevelsComparisonRecap :role="['w_tuteur']" type="sequence"
                              :studentSequence="sequence.student_sequence_id" :key="keycomponent" />
                            <div v-if="currentUser.role === 'tuteur'" class="has-text-centered">
                              <b-button type="is-success" rounded class="mr-2" @click="
                                validateDefEval(
                                  sequence.activities[1].student_activity_id,
                                  sequence.student_sequence_id
                                )
                                ">Valider</b-button>
                              <b-button type="is-danger" rounded @click="
                                conflictAlert(
                                  sequence.activities[1].student_activity_id,
                                  sequence.student_sequence_id
                                )
                                ">Signaler un litige</b-button>
                            </div>
                          </div>
                          <div v-else class="mt-5">
                            <b-message type="is-light" size="is-small">
                              <div v-if="sequence.notes_apprenant === 0">
                                Aucune compétence n'a été évaluée par
                                l'apprenant•e.
                              </div>
                              <div v-else-if="sequence.notes_apprenant === 1">
                                1 compétence a été évaluée par l'apprenant•e.
                              </div>
                              <div v-else-if="sequence.notes_apprenant > 1">
                                {{ sequence.notes_apprenant }} compétences ont
                                été évaluées par l'apprenant•e.
                              </div>
                              <div v-if="sequence.notes_tuteur === 0">
                                Aucune compétence n'a été évaluée par le tuteur
                                ou la tutrice.
                              </div>
                              <div v-else-if="sequence.notes_tuteur === 1">
                                1 compétence a été évaluée par le tuteur ou la
                                tutrice.
                              </div>
                              <div v-else-if="sequence.notes_tuteur > 1">
                                {{ sequence.notes_apprenant }} compétences ont
                                été évaluées par le tuteur ou la tutrice.
                              </div>
                            </b-message>
                          </div>
                        </div>
                        <div v-else-if="sequence.student_sequence_status === 7">
                          <div v-if="
                            sequence.notes_apprenant > 0 &&
                            sequence.notes_tuteur > 0
                          ">
                            <div class="has-text-centered" v-if="sequence.student_sequence_status === 7">
                              <b-button v-if="
                                currentUser.role === 'formateur' ||
                                currentUser.role === 'coordinateur'
                              " type="is-success" rounded @click="
                                endConflict(
                                  sequence.activities[1].student_activity_id,
                                  sequence.student_sequence_id
                                )
                                ">Lever le litige</b-button>
                            </div>
                            <SkillsLevelsComparisonRecap :role="['w_tuteur']" type="sequence"
                              :studentSequence="sequence.student_sequence_id" :key="keycomponent" :nomodify="true" />
                            <div class="has-text-centered" v-if="sequence.student_sequence_status === 7">
                              <b-button v-if="
                                currentUser.role === 'formateur' ||
                                currentUser.role === 'coordinateur'
                              " type="is-success" class="mx-auto mb-5" rounded @click="
                                endConflict(
                                  sequence.activities[1].student_activity_id,
                                  sequence.student_sequence_id
                                )
                                ">Lever le litige</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="sequence.activities[1].steps[0].student_step_entry && sequence.activities[1].steps[0].student_step_entry !== '&nbsp;'">
                        <span class="mb-4 subtitle-paragraph"><b>Commentaire
                            tuteur</b></span>
                        <div v-html="sequence.activities[1].steps[0].student_step_entry" class="mb-5"></div>
                      </div>
                    </b-collapse>
                  </div>
                </section>
              </b-carousel-item>
            </b-carousel>
          </b-step-item>

          <b-step-item step="3" label="Valider" :clickable="true" :type="{ 'is-success': threeSituations > 2 }">
            <b-message type="is-danger" has-icon v-if="isConflictual"><b>Attention il y a un litige</b>
              <div>
                <b> Le référent pédagogique doit intervenir.</b>
                Le semestre ne pourra pas être validé tant que la personne
                référente pédagogique n'aura pas débloqué la situation.
              </div>
            </b-message>
            <div v-if="currentUser.role == 'tuteur' && !validation.status" class="mt-5 pt-5">
              <div class="question">
                <p>
                  Selon vous, à l’issue du semestre, les compétences globales
                  développées par l’élève sont-elles en accord avec le niveau
                  attendu ?
                </p>
              </div>
              <div class="has-text-centered mb-3">
                <p>
                  <b>Votre réponse conditionne l’attribution des crédits
                    ECTS.</b>
                </p>
                <p>
                  Si les axes de progrès sont importants et que vous estimez que
                  l’élève a de gros efforts à fournir, vous pouvez refuser la
                  délivrance des crédits ECTS. Au semestre suivant, si les
                  améliorations nécessaires sont constatées, les crédits ECTS
                  manquants pourront être réattribués.
                </p>
              </div>
              <b-field label="Souhaitez-vous ajouter un commentaire ?" style="font-size: 1.2rem" id="textarea">
                <b-input maxlength="200" type="textarea" v-model="formWaypoint.tutor_comment">
                </b-input>
              </b-field>
            </div>
            <div v-else>
              <div v-if="waypoint.tutor_comment">
                <div class="title2">Commentaires du tuteur</div>
                {{ waypoint.tutor_comment }}
              </div>
            </div>
            <div v-if="threeSituations > 2" class="mt-5">
              <div v-if="this.validation">
                <b-message type="is-success" v-if="
                  this.validated !== 'Effectué' &&
                  !this.validation.validation_id
                ">La fiche semestre peut être validée !</b-message>
                <div v-else-if="!this.validation">
                  <b-message type="is-success" v-if="this.validated === 'Effectué'">La fiche semestre est validée
                    !</b-message>
                </div>
              </div>
              <div v-else>
                <b-message type="is-success" v-if="this.validated !== 'Effectué'">La fiche semestre peut être validée
                  !</b-message>
                <b-message type="is-success" v-else>La fiche semestre est validée !</b-message>
              </div>
              <div class="is-flex align-items-center" style="z-index: 2000" v-if="this.validation">
                <div v-if="!error" class="w-100">
                  <div class="w-100 is-flex" v-if="
                    !this.validation.status_label ||
                    this.validation.status_label === 'A faire' ||
                    this.validation.status_label === 'En retard'
                  ">
                    <div class="mx-auto">
                      <b-button @click="validateWaypoint(1)" class="btn-action w-auto" type="is-success is-rounded"
                        rounded :disabled="validation_tuteur === false">valider</b-button>
                      <b-button v-if="currentUser.role !== 'apprenant'" @click="noValidate(2)" class="btn-action w-auto"
                        type="is-danger is-rounded" rounded :disabled="validation_tuteur === true">refuser</b-button>
                      <b-button @click="validateWaypoint(3)" v-if="
                        currentUser.role === 'coordinateur' ||
                        currentUser.role === 'formateur'
                      " class="btn-action w-auto" type="is-primary is-rounded" rounded outlined>suspendre</b-button>
                    </div>
                  </div>
                  <div v-else class="">
                    <b-message type="is-success" class="w-100 has-text-centered"
                      v-if="this.validation.status_label === 'Valide'"><b>Vous avez validé ce point d'étape</b>
                    </b-message>
                    <b-message type="is-danger" class="w-100 has-text-centered"
                      v-else-if="this.validation.status_label === 'Non valide'"><b>Vous avez refusé de valider ce point
                        d'étape</b>
                    </b-message>
                    <b-message type="is-warning" v-else-if="this.validation.status_label === 'Annulé'"
                      class="has-text-danger has-text-centered">
                      <div class="is-flex align-items-center justify-content-center">
                        <b>Pour le moment, vous avez suspendu les crédits de ce
                          semestre.</b><b-button class="ml-2" @click="quarantaineEdit = !quarantaineEdit" rounded><span
                            v-if="!quarantaineEdit">modifier</span><span v-else>fermer</span></b-button>
                      </div>
                      <div class="mt-4" v-if="quarantaineEdit">
                        Si vous pensez qu'entre temps les améliorations
                        nécessaires ont été constatées, vous pouvez valider les
                        crédits ECTS manquants.
                        <div class="mt-4">
                          <b-button @click="validateWaypoint(1, 'patch')" class="btn-action w-auto"
                            type="is-success is-rounded" rounded
                            :disabled="validation_tuteur === false">valider</b-button>
                          <b-button v-if="currentUser.role !== 'apprenant'" @click="noValidate(2, 'patch')"
                            class="btn-action w-auto" type="is-danger is-rounded" rounded
                            :disabled="validation_tuteur === true">refuser</b-button>
                        </div>
                      </div>
                    </b-message>
                  </div>
                </div>
                <b-message v-else type="is-info" size="is-small" class="has-text-centered w-100">Pour valider le point
                  vous devez
                  avoir rempli toutes les
                  évaluations tuteur</b-message>
              </div>
            </div>
            <div v-else class="mt-5">
              <div v-if="!isConflictual">
                <b-message type="is-danger">Le semestre pourra être validé quand les 3 situations auront
                  été saisies, évaluées et validées</b-message>
                <div v-if="currentUser.role === 'formateur' && isDirector">
                  <div class="w-100 is-flex" v-if="
                    !this.validation.status_label ||
                    this.validation.status_label === 'A faire' ||
                    this.validation.status_label === 'En retard'
                  ">
                    <div class="mx-auto">
                      <b-button @click="validateWaypoint(1)" class="btn-action w-auto" type="is-success is-rounded"
                        rounded :disabled="validation_tuteur === false">valider</b-button>
                      <b-button v-if="currentUser.role !== 'apprenant'" @click="noValidate(2)" class="btn-action w-auto"
                        type="is-danger is-rounded" rounded :disabled="validation_tuteur === true">refuser</b-button>
                      <b-button @click="validateWaypoint(3)" v-if="
                        currentUser.role === 'coordinateur' ||
                        currentUser.role === 'formateur'
                      " class="btn-action w-auto" type="is-primary is-rounded" rounded outlined>suspendre</b-button>
                    </div>
                  </div>
                  <div v-else class="">
                    <b-message type="is-success" class="w-100 has-text-centered"
                      v-if="this.validation.status_label === 'Valide'"><b>Vous avez validé ce point d'étape</b>
                    </b-message>
                    <b-message type="is-danger" class="w-100 has-text-centered"
                      v-else-if="this.validation.status_label === 'Non valide'"><b>Vous avez refusé de valider ce point
                        d'étape</b>
                    </b-message>
                    <b-message type="is-warning" v-else-if="this.validation.status_label === 'Annulé'"
                      class="has-text-danger has-text-centered">
                      <div class="is-flex align-items-center justify-content-center">
                        <b>Pour le moment, vous avez suspendu les crédits de ce
                          semestre.</b><b-button class="ml-2" @click="quarantaineEdit = !quarantaineEdit" rounded><span
                            v-if="!quarantaineEdit">modifier</span><span v-else>fermer</span></b-button>
                      </div>
                      <div class="mt-4" v-if="quarantaineEdit">
                        Si vous pensez qu'entre temps les améliorations
                        nécessaires ont été constatées, vous pouvez valider les
                        crédits ECTS manquants.
                        <div class="mt-4">
                          <b-button @click="validateWaypoint(1, 'patch')" class="btn-action w-auto"
                            type="is-success is-rounded" rounded
                            :disabled="validation_tuteur === false">valider</b-button>
                          <b-button v-if="currentUser.role !== 'apprenant'" @click="noValidate(2, 'patch')"
                            class="btn-action w-auto" type="is-danger is-rounded" rounded
                            :disabled="validation_tuteur === true">refuser</b-button>
                        </div>
                      </div>
                    </b-message>
                  </div>
                </div>
              </div>
            </div>
            <div class="cardValidation">
              <div class="mb-3">
                <b class="title-paragraph" style="font-size: 1.2rem">Validations attendues</b>
              </div>
              <div v-if="booklet_validation.data" class="is-flex align-items-center justify-content-center">
                <div class="b-steps is-small w-100">
                  <nav class="steps is-rounded">
                    <ul class="step-items">
                      <li class="step-item is-previous" v-for="validation in booklet_validation.data[0].data[0]
                        .validations" :key="validation.id">
                        <a class="step-link">
                          <div class="step-marker">
                            <img v-if="validation.status_label == 'Valide'"
                              :src="require(`@/assets/img/steps/status3.svg`)" alt="" class="img_status mx-auto" />

                            <img v-else-if="validation.status_label == 'A faire'"
                              :src="require(`@/assets/img/steps/status6.svg`)" alt="" class="img_status mx-auto" />
                            <img v-else-if="validation.status_label == 'En retard'" :src="require(`@/assets/img/steps/status-late.svg`)
                              " alt="" class="img_status mx-auto" />
                            <img v-else :src="require(`@/assets/img/steps/status5.svg`)" alt=""
                              class="img_status mx-auto" />
                          </div>
                          <div class="step-details">
                            <span class="step-title">
                              {{ validation.name }}</span>
                          </div>
                          <div class="mt-2">
                            <b-tag v-if="validation.status_label == 'Valide'" type="is-success">
                              {{ validation.status_label }}</b-tag>
                            <b-tag v-else-if="validation.status_label == 'A faire'" type="is-secondary is-outlined">
                              {{ validation.status_label }}</b-tag>
                            <b-tag v-else-if="validation.status_label == 'En retard'" class="is-outlined">
                              {{ validation.status_label }}</b-tag>
                            <b-tag type="is-danger is-light" v-else-if="validation.status_label == 'Annulé'">
                              Suspendu</b-tag>
                            <b-tag v-else type="is-danger">
                              {{ validation.status_label }}</b-tag>
                            <div class="mt-2 datetime" v-if="validation.validation_datetime">
                              {{ validation.validation_datetime | day }}
                            </div>
                            <span class="step-name">
                              {{ validation.validator_name }}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </b-step-item>
        </b-steps>
      </div>
      <div class="mb-5"></div>
    </section>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import { mapState } from 'vuex';
import moment from 'moment';
// import AppHelpMessage from '@/components/AppHelpMessage.vue';
import _ from 'lodash';
import SkillsLevelsComparisonRecap from '@/components/Skills/SkillsLevelsComparisonRecap.vue';

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    // AppHelpMessage,
    ckeditor: CKEditor.component,
    SkillsLevelsComparisonRecap,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    // waypoint() {
    //   return this.$store.state.waypoint;
    // },
    error() {
      return this.skillsStudent.length || this.skillsTutor.length;
    },
    doneStudent() {
      // check how much sequences has a done status and return number
      let done = 0;
      for (let i = 0; i < this.listexperiences[0].sequences.length; i += 1) {
        if (this.listexperiences[0].sequences[i].student_sequence_status === 3
          || this.listexperiences[0].sequences[i].student_sequence_status === 4
          || this.listexperiences[0].sequences[i].student_sequence_status === 7) {
          done += 1;
        }
      }
      return done;
    },
    validatedTutor() {
      // check how much sequences has a validated status and return number
      let validated = 0;
      for (let i = 0; i < this.listexperiences[0].sequences.length; i += 1) {
        if (this.listexperiences[0].sequences[i].student_sequence_status === 4) {
          validated += 1;
        }
      }
      return validated;
    },
    evalTutor() {
      let totalTutor = 0;
      const experiencesDone = [];
      for (let i = 0; i < this.listexperiences[0].sequences.length; i += 1) {
        if (this.listexperiences[0].sequences[i].student_sequence_status === 6
          || this.listexperiences[0].sequences[i].student_sequence_status === 3
          || this.listexperiences[0].sequences[i].student_sequence_status === 4
          || this.listexperiences[0].sequences[i].student_sequence_status === 7) {
          experiencesDone.push(this.listexperiences[0].sequences[i]);
        }
        if (i === (this.listexperiences[0].sequences.length - 1)) {
          for (let j = 0; j < experiencesDone.length; j += 1) {
            totalTutor += experiencesDone[j].notes_tuteur;
          }
        }
      }
      return totalTutor;
    },
    evaluated() {
      let doneTutor = 0;
      for (let i = 0; i < this.listexperiences[0].sequences.length; i += 1) {
        if (this.listexperiences[0].sequences[i].notes_tuteur > 0) {
          doneTutor += 1;
        }
      }
      return doneTutor;
    },
    threeSituations() {
      let done = 0;
      let doneTutor = 0;
      const experiencesDone = [];
      for (let i = 0; i < this.listexperiences[0].sequences.length; i += 1) {
        if (this.listexperiences[0].sequences[i].student_sequence_status === 4
          || this.listexperiences[0].sequences[i].student_sequence_status === 7) {
          done += 1;
          experiencesDone.push(this.listexperiences[0].sequences[i]);
        }
        if (i === (this.listexperiences[0].sequences.length - 1)) {
          if (done === 3 || done > 3) {
            for (let j = 0; j < experiencesDone.length; j += 1) {
              if (experiencesDone[j].notes_tuteur > 0) {
                doneTutor += 1;
                if (doneTutor === 3 || doneTutor > 3) {
                  done = 3;
                }
              }
            }
          }
        }
      }
      return doneTutor;
    },
    isConflictual() {
      // check how much sequences has a done status and return number
      let conflict = false;
      for (let i = 0; i < this.listexperiences[0].sequences.length; i += 1) {
        if (this.listexperiences[0].sequences[i].student_sequence_status === 7) {
          conflict = true;
        }
      }
      return conflict;
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList',
          '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      validation_tuteur: null,
      role: 'directeur',
      isLoading: false,
      isFullPage: false,
      waypoint: [],
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: '',
        end_date: '',
        reports: [],
        validated: false,
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      booklet_validation: [],
      validation: [],
      skillsLevels: [],
      selectedRole: '',
      keycomponent: 0,
      editHelpText: false,
      isDirector: null,
      listexperiences: [],
      periodPart: '',
      noskillsTutor: null,
      skillsStudent: [],
      skillsTutor: [],
      activeStep: 0,
      validated: false,
      skillsOpen: false,
      quarantaineEdit: false,
    };
  },
  methods: {
    handleCustomChange(s) {
      this.skillsStudent = s;
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initDirector() {
      this.startLoading();
      // check if currentUser is director
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={directors}&format=json`)
        .then((response) => {
          const director = response.data.directors;
          if (director.includes(this.currentUser.id)) {
            this.isDirector = true;
          }
          this.initValidations();
        });
    },
    initValidations() {
      // get validations for this booklet part
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=${this.$route.params.simple_booklet_part_validation}&student_training=${this.$route.params.idStudentTraining}`)
        .then((response) => {
          this.booklet_validation = response.data;
          this.validated = this.booklet_validation.data[0].data[0].user_status_label;
          // if currentUser is 'director' find good validation
          // because director has a teacher role as 'simple teacher'
          // else find by currentUser.role
          if (this.isDirector) {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', 'directeur']);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          } else {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', this.currentUser.role]);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          }
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données de la partie n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    // initWaypoint(id) {
    //   // this.startLoading();
    //   if (!this.waypoint || this.waypoint.id !== id) {
    //     this.startLoading();
    //     const urlWs = `/agenda/student_training_waypoints/${id}/?format=json`;
    //     this.$http
    //       .get(`${urlWs}`)
    //       .then((response) => {
    //         // this.waypoint = response.data;
    //         this.$store.commit('GET_WAYPOINT_ACTIVITIES', response.data);
    //         if (!this.waypoint.validated) {
    //           this.getActivities();
    //         } else { this.getWaypoint(this.waypoint.id); }
    //       });
    //   }
    // },
    initWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/?format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypoint = response.data;
          this.endLoading();
          // this.$store.commit('GET_WAYPOINT_ACTIVITIES', response.data);
          // this.getActivities();
          if (!this.waypoint.validated) {
            this.getActivities();
          }
          // } else { this.getWaypoint(this.waypoint.id); }
        });
    },
    postValidation(statusValidation) {
      let UserRole = '';
      if (this.isDirector) {
        UserRole = 'directeur';
      } else {
        UserRole = this.currentUser.role;
      }
      this.$http
        .post('/validations/validation/', {
          role: UserRole,
          student_training: Number(this.$route.params.idStudentTraining),
          simple_booklet_part_validation: this.validation.id,
          status: statusValidation,
          student_training_waypoint: this.waypoint.id,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'L\'étape a bien été validée',
            type: 'is-success',
          });
          this.validationWaypoint();
          this.$router.push({
            name: 'StudentTraining',
            params: {
              idStudent: this.$route.params.idStudent,
              idTraining: this.$route.params.idTraining,
              idStudentTraining: this.$route.params.idStudentTraining,
              tab: 'waypoint',
            },
          });
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre validation n\'a pas pu être enregistrée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchValidation(statusValidation) {
      this.$http
        .patch(`/validations/validation/${this.validation.validation_id}/`, {
          status: statusValidation,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'L\'étape a bien été validée',
            type: 'is-success',
          });
          this.validationWaypoint();
          this.$router.push({
            name: 'StudentTraining',
            params: {
              idStudent: this.$route.params.idStudent,
              idTraining: this.$route.params.idTraining,
              idStudentTraining: this.$route.params.idStudentTraining,
              tab: 'waypoint',
            },
          });
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre validation n\'a pas pu être enregistrée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    validateWaypoint(statusValidation, type) {
      if (statusValidation === 1) {
        this.$buefy.dialog.confirm({
          title: 'Souhaitez-vous bien valider ce semestre ?',
          message: 'Vous êtes sur le point de valider ce point et le semestre de votre apprenant•e. Vous confirmez que vous avez bien relu le point d\'étape et le validez.',
          confirmText: 'Valider',
          cancelText: 'Relire',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => {
            if (type === 'patch') {
              this.patchValidation(statusValidation);
            } else { this.postValidation(statusValidation); }
          },
        });
      } if (statusValidation === 3) {
        this.$buefy.dialog.confirm({
          title: 'Souhaitez-vous suspendre ce semestre ?',
          message: 'Vous êtes sur le point de mettre en quarantaine les crédits ects associés à ce semestre. Au semestre suivant, si les améliorations nécessaires sont constatées, les crédits ECTS manquants pourront être réattribués. ',
          confirmText: 'Suspendre',
          cancelText: 'Relire',
          type: 'is-warning',
          hasIcon: false,
          onConfirm: () => {
            if (type === 'patch') {
              this.patchValidation(statusValidation);
            } else { this.postValidation(statusValidation); }
          },
        });
      }
    },
    noValidate(statusValidation, type) {
      this.$buefy.dialog.confirm({
        title: 'Refusez-vous bien de valider ce semestre ?',
        message: 'Vous confirmez que votre apprenant•e n\'a pas le niveau attendu et que vous lui refusez l\'attribution des crédits ECTS pour ce semestre.',
        confirmText: 'Refuser',
        cancelText: 'Relire',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          if (type === 'patch') {
            this.patchValidation(statusValidation);
          } else { this.postValidation(statusValidation); }
          this.goPreviousPage();
        },
      });
    },
    validationWaypoint() {
      this.$http
        .patch(`/agenda/student_training_waypoints/${this.waypoint.id}/`, {
          validated: true,
          tutor_comment: this.formWaypoint.tutor_comment,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Point d\'étape a bien été validé',
            type: 'is-success',
          });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goPreviousPage() {
      // if (this.$route.params.from) {
      //   this.$router.push({
      //     name: this.$route.params.from,
      //     params: {
      //       tabDashboard: 'validationStep',
      //       idTraining: this.$route.params.idTraining,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'StudentTraining',
      //     params: {
      //       idStudent: this.$route.params.idStudent,
      //       idTraining: this.$route.params.idTraining,
      //       idStudentTraining: this.$route.params.idStudentTraining,
      //       tab: 'waypoint',
      //     },
      //   });
      // }
      this.$router.go(-1);
    },
    getWaypoint(idWaypoint) {
      // this.startLoading();
      this.$http
        .get(`/get_waypoint/${idWaypoint}?format=json`)
        .then((response) => {
          // this.waypoint = response.data;
          this.$store.commit('GET_WAYPOINT_ACTIVITIES', response.data);
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données waypoint n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    order(array) {
      return _.orderBy(array, 'sequence_position');
    },
    orderPosition(array) {
      return _.orderBy(array, 'position');
    },
    initLevels() {
      this.$http
        .get(`/traininglevels/?training=${this.$route.params.idTraining}&format=json`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
    postWaypoint() {
      if (this.waypoint.type === 0) {
        for (let i = 0; i < this.student_activities_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.student_activities_report[i].autonomy),
              report: this.student_activities_report[i].report,
              student_activity: this.student_activities_report[i].student_activity,
            },
          );
        }
        for (let i = 0; i < this.student_revisions_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.student_revisions_report[i].autonomy),
              report: this.student_revisions_report[i].report,
              student_activity_revision: this.student_revisions_report[i].student_activity_revision,
            },
          );
        }
        for (let i = 0; i < this.free_situations_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.free_situations_report[i].autonomy),
              report: this.free_situations_report[i].report,
              free_situation: this.free_situations_report[i].free_situation,
            },
          );
        }
      }
      this.$http
        .post('agenda/set_waypoint', {
          id: this.waypoint.id,
          type: this.waypoint.type,
          student_training: this.$route.params.idStudentTraining,
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          role: this.currentUser.role,
          profile: Number(this.currentUser.id),
          goals: this.formWaypoint.goals,
          comment: this.formWaypoint.comment,
          tutor_comment: this.formWaypoint.tutor_comment,
          teacher_comment: this.formWaypoint.teacher_comment,
          reports: this.arrayReports,
          validated: false,
        }).then(() => {
          // this.getWaypoint(this.waypoint.id);
          this.$buefy.toast.open({ message: 'Point d\'étape enregistré', type: 'is-success' });
          // this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    // getPeriodActivitiesMade() {
    //   this.listexperiences.length.forEach((experience) => {
    //     if (experience.status === 6 || experience.status === 3 || experience.status === 4) {
    //       this.arrayReports.push(
    //         {
    //           autonomy: 0,
    //           report: null,
    //           student_activity: this.student_activities_report[i].student_activity,
    //         },
    //       );
    //     }
    //   });
    // },
    getActivities() {
      const urlWs = `/agenda/get_waypoint_activities?student_training=${this.$route.params.idStudentTraining}&period=${this.periodPart}&existing_report=false`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.activities = response.data;
          for (let i = 0; i < this.activities.student_activities.length; i += 1) {
            this.student_activities_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity: this.activities.student_activities[i].id,
                student_sequence: this.activities.student_activities[i].sequence,
                sequence_title: this.activities.student_activities[i].sequence_title,
                sequence_subtitle: this.activities.student_activities[i].sequence_subtitle,
                title: this.activities.student_activities[i].title,
              },
            );
          }
          // for (let i = 0; i < this.activities.student_activity_revisions.length; i += 1) {
          //   const activitiesArray = this.activities.student_activity_revisions;
          //   this.student_revisions_report.push(
          //     {
          //       autonomy: 0,
          //       report: null,
          //       student_activity_revision: this.activities.student_activity_revisions[i].id,
          //       title: this.activities.student_activity_revisions[i].title,
          //       student_sequence_title: activitiesArray[i].student_sequence_title,
          //       student_sequence_subtitle: activitiesArray[i].student_sequence_subtitle,
          //     },
          //   );
          // }
          // for (let i = 0; i < this.activities.free_situations.length; i += 1) {
          //   this.free_situations_report.push(
          //     {
          //       autonomy: 0,
          //       report: null,
          //       free_situation: this.activities.free_situations[i].id,
          //       title: this.activities.free_situations[i].title,
          //     },
          //   );
          // }
          if (this.activities.student_activities.length
            || this.activities.student_activity_revisions.length
            || this.activities.free_situations.length) {
            // if activities add them to waypoint
            this.postWaypoint();
          } else {
            // if no new activities get stats
            // this.getWaypoint(this.waypoint.id);
          }
          // this.getWaypoint(this.waypoint.id);
        });
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    gotoActivity(activityId, sequenceId, revisionId, freeId) {
      if (activityId) {
        this.$router.push({
          name: 'StudentActivity',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idStudent: this.$route.params.idStudent,
            idActivity: activityId,
            idSequence: sequenceId,
            from: this.$route.name,
            simple_booklet_part_validation: this.$route.params.simple_booklet_part_validation,
          },
        });
      } else if (revisionId) {
        this.$router.push({
          name: 'StudentProgressionRead',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idStudent: this.$route.params.idStudent,
            idRevision: revisionId,
            idSequence: sequenceId,
            simple_booklet_part_validation: this.$route.params.simple_booklet_part_validation,
            from: this.$route.name,
          },
        });
      } else if (freeId) {
        this.$router.push({
          name: 'StudentFreeSituationRead',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idStudent: this.$route.params.idStudent,
            idFree: freeId,
            simple_booklet_part_validation: this.$route.params.simple_booklet_part_validation,
            from: this.$route.name,
          },
        });
      }
    },
    modifyTexts() {
      this.editHelpText = true;
    },
    saveHelpTexts() {
      this.startLoading();
      this.$http
        .patch(`booklet_parts/${this.$route.params.simple_booklet_part_validation}/`, {
          content: this.booklet_validation.part_content,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Fiche enregistrée', type: 'is-success' });
          this.editHelpText = false;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'information n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    listPeriod() {
      // find experiences list for this period
      this.$http
        .get(`agenda/student_training_detail/?training_id=${this.$route.params.idTraining}&student_id=${this.$route.params.idStudent}&all=1&evals&period_id=${this.periodPart}&format=json`)
        .then((response) => {
          this.listexperiences = response.data.periods;
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données liste période n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    getPeriodPart() {
      // check which period is related to this part
      // this.startLoading();
      this.$http
        .get(`booklet_parts/${this.$route.params.simple_booklet_part_validation}/`)
        .then((response) => {
          const period = response.data;
          this.periodPart = response.data.periods[0].id;
          this.listPeriod();
          const studentTraining = Number(this.$route.params.idStudentTraining);
          const entry = _.findKey(period.entries,
            ['student_training', studentTraining]);
          if (entry) {
            const thisWaypoint = period.entries[entry].student_training_waypoint;
            this.initWaypoint(thisWaypoint);
          } else {
            this.createWaypoint(response.data.id, period.start_date, period.end_date);
          }
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données period part n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    createWaypoint(id, start, end) {
      // create waypoint
      this.$http
        .post('agenda/student_training_waypoints/', {
          start_date: start,
          end_date: end,
          type: 0,
          student_training: this.$route.params.idStudentTraining,
        }).then((response) => {
          this.$http
            .post('booklet_parts_entries/', {
              simple_booklet_part: id,
              student_training: this.$route.params.idStudentTraining,
              student_training_waypoint: response.data.id,
            }).then(() => {
              this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
              // this.gotoWaypointEdit(id, response.data.id);
              this.initWaypoint(response.data.id);
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être enregistré.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gotoWaypointEdit(idpart, idwaypoint) {
      this.$router.push({
        name: 'WaypointitiiEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: idwaypoint,
          simple_booklet_part_validation: idpart,
        },
      });
    },
    patchStatusActivity(id, statusActivity) {
      this.$http
        .patch(`/agenda/student_activity/${id}/`, {
          status: statusActivity,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    conflictAlert(idActivity, idSequence) {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous indiquer un litige à l\'équipe pédagogique ?',
        message: 'Si vous n\'arrivez pas à dialoguer avec votre apprenant•e et à vous mettre d\'accord sur l\'évaluation des compétences, vous pouvez l\'indiquer.',
        confirmText: 'Signaler',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/agenda/student_sequence/${idSequence}/`, {
              status: 7,
            })
            .then(() => {
              this.patchStatusActivity(idActivity, 7);
              this.$buefy.toast.open({ message: 'Litige indiqué', type: 'is-success' });
              this.listPeriod();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    validateDefEval(idActivity, idSequence) {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider définitivement votre activité ?',
        message: 'N\'hésitez pas à bien relire votre réponse. Sinon, pas d\'inquiétude, vous pourrez la reprendre plus tard.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/agenda/student_activity/${idActivity}/`, {
              status: 4,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Activité validée', type: 'is-success' });
              this.$http
                .patch(`/agenda/student_sequence/${idSequence}/`, {
                  status: 4,
                })
                .then(() => {
                  this.$buefy.toast.open({ message: 'Expérience validée', type: 'is-success' });
                  this.listPeriod();
                }).catch((error) => {
                  window.console.log(error);
                  this.errored = true;
                  this.$buefy.dialog.alert({
                    title: 'Mince...',
                    message: 'Le statut de l\'experience n\'a pas pu être modifié.',
                    type: 'is-info',
                    hasIcon: true,
                    icon: 'times-circle',
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                    confirmText: 'Ok',
                  });
                });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifié.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    endConflict(idActivity, idSequence) {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous lever le conflit ?',
        message: 'Cela permettra à chacun de valider le semestre. Le tuteur ou la tutrice pourra ainsi reprendre la comparaison des compétences évaluées avec son apprenant•e et valider.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/agenda/student_activity/${idActivity}/`, {
              status: 3,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Activité validée', type: 'is-success' });
              this.$http
                .patch(`/agenda/student_sequence/${idSequence}/`, {
                  status: 3,
                })
                .then(() => {
                  this.$buefy.toast.open({ message: 'Expérience validée', type: 'is-success' });
                  this.listPeriod();
                }).catch((error) => {
                  window.console.log(error);
                  this.errored = true;
                  this.$buefy.dialog.alert({
                    title: 'Mince...',
                    message: 'Le statut de l\'experience n\'a pas pu être modifié.',
                    type: 'is-info',
                    hasIcon: true,
                    icon: 'times-circle',
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                    confirmText: 'Ok',
                  });
                });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifié.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
  },
  created() {
    this.initDirector();
    // this.initLevels();
    this.getPeriodPart();
    // this.listPeriod();
    // this.startLoading();
    // this.initWaypoint();
  },
});
</script>

<style lang="scss" scoped>
.card_buttons {
  margin-left: auto !important;
  min-width: 198px;
}

.titles {
  max-width: 460px;
  padding-right: 1rem;
}

.studentName {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
}

.question {
  font-size: 1.2rem;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  max-width: 700px;
  margin: auto;
}

.card-header2 {
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  padding: 1rem 1rem 1rem 0.5rem;

  .date {
    color: var(--primary);
  }

  .card-header-title {
    font-size: 1rem;
  }

  .card-header-icon {
    width: 4.125rem;
  }
}

.card_skill {
  background: #ffffff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: 2px 2px 2px 2px 0;
  padding: 1rem;
  border: 1px solid #eff1f7;
}

.title2 {
  color: var(--primary);
}

.number-primary {
  padding: 0.3rem 0.6rem;
  border-radius: 30px;
  background-color: var(--primary);
  color: #fff;
  margin-right: 0.2rem;
}

.bg-grey {
  background-color: #f4f5f9;
  margin-bottom: 0.4rem;
}

#textarea {
  label {
    font-size: 1rem;
  }
}

.step_marker {
  background-color: #fff !important;
}

@media screen and (max-width: 768px) {
  .card_activity::after {
    content: unset;
    color: #c1bbd0cc;
  }

  .card_activity {
    border-bottom: 1px solid #ededed;
  }

  .card_activity:last-child {
    border-bottom: 0px solid #ededed;

    &::after {
      content: unset;
    }
  }

  .card-header2 .content-activity {
    flex-direction: column;

    &>div,
    &>span,
    &>button {
      margin-left: 0px !important;
      margin-right: auto !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .content-steps {
    flex-direction: column;
  }

  .card_buttons {
    margin-left: 0px !important;
    padding-top: 0.5rem;
  }

  // .actions-icon,
  // .img_status {
  //   display: block;
  // }
  .titles {
    flex-direction: column;
  }

  .caption-item .img_status {
    display: block;
  }
}

.step-content {
  padding: 0 !important;
}

.bookletSteps img {
  width: 40px;
  height: 40px;
}

.img_status {
  margin: 0rem;
}

#waypointModal .levelsList {
  flex-direction: column !important;
}

.title {
  font-size: 1.2rem;
}

.message .message-body .media {
  align-items: center;
  padding: 0.2rem 1 rem;
  margin-top: 1rem;
}
</style>
